import commonDataAccess from "../commonDataAccess";
import { IFilter } from "../../@redux/listRedux";

class clientProductDataAccess {
  // updateClientProduct(params: { id: string }) {
  //     return commonDataAccess.putData("client/update/client", null, params);
  // }
  clientProductList = async (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return await commonDataAccess.getData("clientProduct/getAll", parameters);
  };

  getProductStatusList = async (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return await commonDataAccess.getData(
      "clientProduct/get/productStatuses",
      parameters
    );
  };
  getClientProductsByStatus = async (status: string) => {
    var result = await commonDataAccess.getData(
      "clientProduct/get/clientProductsByStatus",
      { status }
    );
    return result;
  };

  getClientProductBasicInfo = async (id: number | bigint | any) => {
    var result = await commonDataAccess.getData(
      "clientProduct/get/clientProductBasicInfo",
      { clientProductId: id }
    );
    return result;
  };
  saveClientProductBasicInfo = async (model: any) => {
    var result = await commonDataAccess.postData(
      "clientProduct/save/clientProductBasicInfo",
      model
    );
    return result;
  };

  getClientProductListsForDropdown = async () => {
    var result = await commonDataAccess.getData(
      "clientProduct/get/clientProductListsForDropdown",
      {}
    );
    return result;
  };

  sendSettledEmail = async (clientProductId: any) => {
    var result = await commonDataAccess.getData(
      "clientProduct/get/sendSettledEmail",
      {
        id: clientProductId,
      }
    );
    return result;
  };

  getFunderListsForDropdown = async () => {
    var result = await commonDataAccess.getData(
      "clientProduct/get/funderListsForDropdown",
      {}
    );
    return result;
  };
  getClientProductStatusListForDropdown = async () => {
    var result = await commonDataAccess.getData(
      "clientProduct/get/clientProductStatusListsForDropdown",
      {}
    );
    return result;
  };

  checkDuplicateClientProduct = async (clientId, productId) => {
    var result = await commonDataAccess.getData(
      "clientProduct/checkDuplicateClientProduct",
      {
        clientId: clientId,
        productId: productId,
      }
    );
    return result;
  };

  getProductStatusBasicInfo = async (id: number | bigint | any) => {
    var result = await commonDataAccess.getData(
      "clientProduct/get/productStatusBasicInfo",
      { productStatusId: id }
    );
    return result;
  };
  saveProductStatusBasicInfo = async (model: any) => {
    var result = await commonDataAccess.postData(
      "clientProduct/save/productStatusBasicInfo",
      model
    );
    return result;
  };
  updateSequenceOnDragDrop = async (draggedId, targetId) => {
    console.log(draggedId);
    console.log(targetId);
    var result = await commonDataAccess.postData(
      "clientProduct/update/updateSequenceOnDragDrop",
      {
        draggedId: draggedId,
        targetId: targetId,
      }
    );
    return result;
  };
}

export default new clientProductDataAccess();
